<template>
  <div
    class="authenticated-layout white"
    :style="$vuetify.breakpoint.smAndUp ? 'height: 100vh' : ''"
  >
    <!-- visible only to smaller screen -->
    <div v-if="$vuetify.breakpoint.smAndDown">
      <div>
        <NavigationDrawerAdMain
          :drawer="showDrawer"
          @drawerUpdated="updateDrawer"
        />
      </div>

      <div class="header-section w-100 sticky index-5" style="top: 0">
        <HeaderMain
          :drawer="showDrawer"
          class="index-5"
          style="top: 0"
          @showDrawer="updateDrawer"
          v-if="$vuetify.breakpoint.smAndDown"
        />
      </div>
      <div class="relative">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <router-view
                class="index-4"
                style="min-height: calc(100vh - 112px - 142px)"
                :style="{
                  padding: $vuetify.breakpoint.mdAndDown ? '4px' : '',
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- visible only to larger screen -->
    <div class="relative white" v-else>
      <div
        style="
          min-height: calc(100vh - 142px);
          overflow-x: hidden;
          overflow-y: hidden;
        "
      >
        <div>
          <v-row class="d-flex">
            <v-col
              cols="12"
              sm="2"
              md="3"
              lg="3"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <LeftSidebarMenu />
            </v-col>
            <v-col
              cols="12"
              :sm="authDashboard ? 12 : 8"
              :md="authDashboard ? 8 : 6"
              :class="authDashboard ? '' : 'pt-2'"
              :lg="authDashboard ? 8 : 8"
            >
              <router-view
                class="index-4"
                style="min-height: calc(100vh - 112px - 142px)"
                :style="{
                  padding: $vuetify.breakpoint.mdAndDown ? '4px' : '',
                }"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSidebarMenu from "@/components/template/dashboard/LeftSidebarMenu";
import NavigationDrawerAdMain from "@/components/template/home/NavigationDrawerAdMain.vue";
import HeaderMain from "@/components/template/HeaderMain";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    LeftSidebarMenu,
    NavigationDrawerAdMain,
    HeaderMain,
  },
  data() {
    return {
      showDrawer: false,
    };
  },
  computed: {
    ...mapGetters("bots", ["bots", "activeBot"]),
    authDashboard() {
      return this.$route.name == "AuthDashboard";
    },
    paramBotId() {
      return this.$route.params?.botId;
    },
  },
  methods: {
    ...mapActions("bots", ["getBots"]),
    ...mapActions("notifications", ["getNotifications"]),

    updateDrawer(val) {
      this.showDrawer = val;
    },
  },
  mounted() {
    this.getBots(this.paramBotId);
  },

  // watch: {
  //   activeBot: {
  //     immediate: true,
  //     handler(v) {
  //       this.getNotifications();
  //     },
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
